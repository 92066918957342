//Correr em local host -> Terminal -> New Terminal: npm start
//Deploy no github -> Terminal -> New Terminal: npm run deploy

import React, { Component } from "react";
import "./App.css";
import { IconContext } from "react-icons";
import { FaCaretLeft, FaCaretRight} from "react-icons/fa";



var compartments_state = "unlocked";
let cancel_timeout;

class App extends Component {

  
  constructor(props) {
    super(props);
    this.state = {

      compartments: [{"compartments":"compartment#1","state":"unlocked"},{"compartments":"compartment#2","state":"unlocked"},{"compartments":"compartment#3","state":"unlocked"},{"compartments":"compartment#4","state":"unlocked"},{"compartments":"compartment#5","state":"unlocked"},{"compartments":"compartment#6","state":"unlocked"},{"compartments":"compartment#7","state":"unlocked"},{"compartments":"compartment#8","state":"unlocked"},{"compartments":"compartment#9","state":"unlocked"},{"compartments":"compartment#10","state":"unlocked"},{"compartments":"compartment#11","state":"unlocked"},{"compartments":"compartment#12","state":"unlocked"},{"compartments":"compartment#13","state":"unlocked"},{"compartments":"compartment#14","state":"unlocked"},{"compartments":"compartment#15","state":"unlocked"},{"compartments":"compartment#16","state":"unlocked"},{"compartments":"compartment#17","state":"unlocked"},{"compartments":"compartment#18","state":"unlocked"},{"compartments":"compartment#19","state":"unlocked"},{"compartments":"compartment#20","state":"unlocked"},{"compartments":"compartment#21","state":"unlocked"},
      {"compartments":"compartment#22","state":"unlocked"},{"compartments":"compartment#23","state":"unlocked"},{"compartments":"compartment#24","state":"unlocked"},{"compartments":"compartment#25","state":"unlocked"},{"compartments":"compartment#26","state":"unlocked"},{"compartments":"compartment#27","state":"unlocked"},{"compartments":"compartment#28","state":"unlocked"},{"compartments":"compartment#29","state":"unlocked"},{"compartments":"compartment#30","state":"unlocked"},{"compartments":"compartment#31","state":"unlocked"},{"compartments":"compartment#32","state":"unlocked"},{"compartments":"compartment#33","state":"unlocked"},{"compartments":"compartment#34","state":"unlocked"},{"compartments":"compartment#35","state":"unlocked"},{"compartments":"compartment#36","state":"unlocked"},{"compartments":"compartment#37","state":"unlocked"},{"compartments":"compartment#38","state":"unlocked"},{"compartments":"compartment#39","state":"unlocked"},{"compartments":"compartment#40","state":"unlocked"},{"compartments":"compartment#41","state":"unlocked"},{"compartments":"compartment#42","state":"unlocked"},{"compartments":"compartment#43","state":"unlocked"},{"compartments":"compartment#44","state":"unlocked"},{"compartments":"compartment#45","state":"unlocked"},{"compartments":"compartment#46","state":"unlocked"},{"compartments":"compartment#47","state":"unlocked"},{"compartments":"compartment#48","state":"unlocked"},{"compartments":"compartment#49","state":"unlocked"},{"compartments":"compartment#50","state":"unlocked"},{"compartments":"compartment#51","state":"unlocked"},{"compartments":"compartment#52","state":"unlocked"},{"compartments":"compartment#53","state":"unlocked"},{"compartments":"compartment#54","state":"unlocked"},{"compartments":"compartment#55","state":"unlocked"},{"compartments":"compartment#56","state":"unlocked"},{"compartments":"compartment#57","state":"unlocked"},],
      
      compartments_: [{"compartments":"compartment_1","state":"unlocked"},{"compartments":"compartment_2","state":"unlocked"},{"compartments":"compartment_3","state":"unlocked"},{"compartments":"compartment_4","state":"unlocked"},{"compartments":"compartment_5","state":"unlocked"},{"compartments":"compartment_6","state":"unlocked"},{"compartments":"compartment_7","state":"unlocked"},{"compartments":"compartment_8","state":"unlocked"},{"compartments":"compartment_9","state":"unlocked"},{"compartments":"compartment_10","state":"unlocked"},{"compartments":"compartment_11","state":"unlocked"},{"compartments":"compartment_12","state":"unlocked"},{"compartments":"compartment_13","state":"unlocked"},{"compartments":"compartment_14","state":"unlocked"},{"compartments":"compartment_15","state":"unlocked"},{"compartments":"compartment_16","state":"unlocked"},{"compartments":"compartment_17","state":"unlocked"},{"compartments":"compartment_18","state":"unlocked"},{"compartments":"compartment_19","state":"unlocked"},{"compartments":"compartment_20","state":"unlocked"},{"compartments":"compartment_21","state":"unlocked"},{"compartments":"compartment_22","state":"unlocked"},{"compartments":"compartment_23","state":"unlocked"},{"compartments":"compartment_24","state":"unlocked"},{"compartments":"compartment_25","state":"unlocked"},{"compartments":"compartment_26","state":"unlocked"},{"compartments":"compartment_27","state":"unlocked"},
      {"compartments":"compartment_28","state":"unlocked"},{"compartments":"compartment_29","state":"unlocked"},{"compartments":"compartment_30","state":"unlocked"},{"compartments":"compartment_31","state":"unlocked"},{"compartments":"compartment_32","state":"unlocked"},{"compartments":"compartment_33","state":"unlocked"},{"compartments":"compartment_34","state":"unlocked"},{"compartments":"compartment_35","state":"unlocked"},{"compartments":"compartment_36","state":"unlocked"},{"compartments":"compartment_37","state":"unlocked"},{"compartments":"compartment_38","state":"unlocked"},{"compartments":"compartment_39","state":"unlocked"},{"compartments":"compartment_40","state":"unlocked"},{"compartments":"compartment_41","state":"unlocked"},{"compartments":"compartment_42","state":"unlocked"},{"compartments":"compartment_43","state":"unlocked"},{"compartments":"compartment_44","state":"unlocked"},{"compartments":"compartment_45","state":"unlocked"},{"compartments":"compartment_46","state":"unlocked"},{"compartments":"compartment_47","state":"unlocked"},{"compartments":"compartment_48","state":"unlocked"},{"compartments":"compartment_49","state":"unlocked"},{"compartments":"compartment_50","state":"unlocked"},{"compartments":"compartment_51","state":"unlocked"},{"compartments":"compartment_52","state":"unlocked"},{"compartments":"compartment_53","state":"unlocked"},{"compartments":"compartment_54","state":"unlocked"},{"compartments":"compartment_55","state":"unlocked"},{"compartments":"compartment_56","state":"unlocked"},{"compartments":"compartment_57","state":"unlocked"}, ],

      lock_state:"LOCK",
      caut_color:"rgb(194,218,218)",

      locked_compartments:[],
      smoke_reset:"none"

    };

    this.activateBins=this.activateBins.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.open_menu=this.open_menu.bind(this)
    this.lockUnlockAll=this.lockUnlockAll.bind(this)
    this.updateState=this.updateState.bind(this)
    this.handleChange_new=this.handleChange_new.bind(this)
    this.handleChange_fa=this.handleChange_fa.bind(this)  // Corresponding to First Aid compartments
    this.smokeReset=this.smokeReset.bind(this)
  }   

handleChange_new (smoke){

  var xx = this.state.compartments
  Object.keys(xx).forEach(function(key) {

  if (xx[key].compartments === smoke)
  xx[key].state = "smoke"

  }
  )

  if(cancel_timeout !== undefined)
    clearTimeout(cancel_timeout)
    cancel_timeout = setTimeout(() => { 
      this.activateBins() 
      this.lockUnlockAll("new_smoke")
     }, 5000)

  
}
  
handleChange_fa (smoke){

  var xx = this.state.compartments
  Object.keys(xx).forEach(function(key) {

    if (xx[key].compartments === smoke)

    xx[key].state = "smoke"

  }
  )

      if(cancel_timeout !== undefined)
        clearTimeout(cancel_timeout)
      cancel_timeout = setTimeout(() => { this.activateBins()  }, 5000)

  
}


handleChange = (id) => {

    
    var input = document.getElementById(id.replace("#",""));


    if(document.readyState === 'complete')// check if page is fully loaded
    
    if(input.checked === true)
      document.getElementById(id).style.background = "#bada55";
  
    else 
      document.getElementById(id).style.background = "#ff0000";
    
    
  }

  
  open_menu = () => {
  document.querySelector('.menubtn').classList.toggle('opened');
  document.querySelector('.navmenu').classList.toggle('opened');
  }
  

updateState(){


var xx = this.state.compartments
var yy = this.state.compartments_
let smoke_reset_decision = 0
Object.keys(xx).forEach(function(key) {

  yy[key].state=xx[key].state


  if(xx[key].state === "smoke")
  smoke_reset_decision++;
})
if (smoke_reset_decision>0){
  this.setState({smoke_reset:"block"})
  this.setState({caut_color:"rgb(265,167,59)"})

}
else
{
  this.setState({smoke_reset:"none"})
  this.setState({caut_color:"rgb(194,218,218)"})
}

Object.keys(xx).forEach(function(key) {


if (xx[key].state === "unlock")
{
  document.getElementById(xx[key].compartments).style.background = "green";
  document.getElementById(yy[key].compartments).style.background = "green";
}

if (xx[key].state === "lock")
{
  document.getElementById(xx[key].compartments).style.background = "orange";
  document.getElementById(yy[key].compartments).style.background = "orange";
}

if (xx[key].state === "smoke")
{
  document.getElementById(xx[key].compartments).style.background = "red";
  document.getElementById(yy[key].compartments).style.background = "red";
}
if (xx[key].state === "fa")
{
  document.getElementById(xx[key].compartments).style.background = "rgb(170,170,170)";
  document.getElementById(yy[key].compartments).style.background = "rgb(170,170,170)";
}
}
)


}


  lockUnlockAll(smoke_warning) {
        var xx = this.state.compartments

if(this.state.lock_state === "LOCK"){
        this.setState({lock_state:"UNLOCK"})

      compartments_state = "locked"
    Object.keys(xx).forEach(function(key) {
if (xx[key].compartments !== "compartment#1" && 
    xx[key].compartments !== "compartment#2" && 
    xx[key].compartments !== "compartment#3" && 
    xx[key].compartments !== "compartment#4" && 
    xx[key].compartments !== "compartment#5" && 
    xx[key].compartments !== "compartment#6" && 
    xx[key].compartments !== "compartment#27" && 
    xx[key].compartments !== "compartment#28" && 
    xx[key].compartments !== "compartment#29" && 
    xx[key].compartments !== "compartment#30" && 
    xx[key].compartments !== "compartment#39" && 
    xx[key].compartments !== "compartment#40" && 
    xx[key].compartments !== "compartment#41" && 
    xx[key].compartments !== "compartment#42" && 
    xx[key].compartments !== "compartment#43" && 
    xx[key].compartments !== "compartment#44" && 
    xx[key].compartments !== "compartment#53" && 
    xx[key].compartments !== "compartment#54" && 
    xx[key].compartments !== "compartment#55" && 
    xx[key].compartments !== "compartment#56" && 
    xx[key].compartments !== "compartment#57" )
    {
      if(xx[key].state!=="smoke")
      if(smoke_warning!=="new_smoke")
      xx[key].state = "lock"
    }

    }
 )
}
    else{


      this.setState({lock_state:"LOCK"})
        compartments_state = "unlocked"
      Object.keys(xx).forEach(function(key) {
        if (xx[key].compartments !== "compartment#1" && 
    xx[key].compartments !== "compartment#2" && 
    xx[key].compartments !== "compartment#3" && 
    xx[key].compartments !== "compartment#4" && 
    xx[key].compartments !== "compartment#5" && 
    xx[key].compartments !== "compartment#6" && 
    xx[key].compartments !== "compartment#27" && 
    xx[key].compartments !== "compartment#28" && 
    xx[key].compartments !== "compartment#29" && 
    xx[key].compartments !== "compartment#30" && 
    xx[key].compartments !== "compartment#39" && 
    xx[key].compartments !== "compartment#40" && 
    xx[key].compartments !== "compartment#41" && 
    xx[key].compartments !== "compartment#42" && 
    xx[key].compartments !== "compartment#43" && 
    xx[key].compartments !== "compartment#44" && 
    xx[key].compartments !== "compartment#53" && 
    xx[key].compartments !== "compartment#54" && 
    xx[key].compartments !== "compartment#55" && 
    xx[key].compartments !== "compartment#56" && 
    xx[key].compartments !== "compartment#57" )
        
    {
      if(xx[key].state!=="smoke")
      xx[key].state = "unlock"
    }

      }
   )
    }
  
   this.updateState()
  }


  smokeReset ()
  {

    if (compartments_state === "unlocked") {
    var xx = this.state.compartments
    Object.keys(xx).forEach(function(key)
      {    
        if (xx[key].compartments !== "compartment#1" && 
    xx[key].compartments !== "compartment#2" && 
    xx[key].compartments !== "compartment#3" && 
    xx[key].compartments !== "compartment#4" && 
    xx[key].compartments !== "compartment#5" && 
    xx[key].compartments !== "compartment#6" && 
    xx[key].compartments !== "compartment#27" && 
    xx[key].compartments !== "compartment#28" && 
    xx[key].compartments !== "compartment#29" && 
    xx[key].compartments !== "compartment#30" && 
    xx[key].compartments !== "compartment#39" && 
    xx[key].compartments !== "compartment#40" && 
    xx[key].compartments !== "compartment#41" && 
    xx[key].compartments !== "compartment#42" && 
    xx[key].compartments !== "compartment#43" && 
    xx[key].compartments !== "compartment#44" && 
    xx[key].compartments !== "compartment#53" && 
    xx[key].compartments !== "compartment#54" && 
    xx[key].compartments !== "compartment#55" && 
    xx[key].compartments !== "compartment#56" && 
    xx[key].compartments !== "compartment#57" )

        xx[key].state = "unlock"     
        else
        xx[key].state = "fa"     

      }
    )
    }

    if (compartments_state === "locked"){
       var xx = this.state.compartments

      Object.keys(xx).forEach(function(key)
        {    
    if (xx[key].compartments !== "compartment#1" && 
    xx[key].compartments !== "compartment#2" && 
    xx[key].compartments !== "compartment#3" && 
    xx[key].compartments !== "compartment#4" && 
    xx[key].compartments !== "compartment#5" && 
    xx[key].compartments !== "compartment#6" && 
    xx[key].compartments !== "compartment#27" && 
    xx[key].compartments !== "compartment#28" && 
    xx[key].compartments !== "compartment#29" && 
    xx[key].compartments !== "compartment#30" && 
    xx[key].compartments !== "compartment#39" && 
    xx[key].compartments !== "compartment#40" && 
    xx[key].compartments !== "compartment#41" && 
    xx[key].compartments !== "compartment#42" && 
    xx[key].compartments !== "compartment#43" && 
    xx[key].compartments !== "compartment#44" && 
    xx[key].compartments !== "compartment#53" && 
    xx[key].compartments !== "compartment#54" && 
    xx[key].compartments !== "compartment#55" && 
    xx[key].compartments !== "compartment#56" && 
    xx[key].compartments !== "compartment#57" )

          xx[key].state = "unlock"     
           else
           xx[key].state = "fa"     


        }
      )
      }


      this.updateState()
  }
  
  activateBins() {
/*
    if (document.getElementById("MainBox").style.display === "none") {
      document.getElementById("MainBox").style.display = "grid";
      document.getElementById("MainBoxTopMenu").style.display = "grid";
      document.getElementById("MainBoxBottomMenu").style.display = "grid";
  
      document.getElementById("BinBox").style.display = "none";
      document.getElementById("BinBoxTopMenu").style.display = "none";
      document.getElementById("BinBoxBottomMenu").style.display = "none";
      
    } else {
      document.getElementById("MainBox").style.display = "none";
      document.getElementById("MainBoxTopMenu").style.display = "none";
      document.getElementById("MainBoxBottomMenu").style.display = "none";
  
      document.getElementById("BinBox").style.display = "grid";
      document.getElementById("BinBoxTopMenu").style.display = "grid";
      document.getElementById("BinBoxBottomMenu").style.display = "grid";

      this.updateState()

    }
    */
    this.updateState()
  };
  componentDidMount() {

    window.document.body.style.zoom = 0.625;
    document.getElementById("MainBox").style.display = "grid";
    document.getElementById("MainBoxTopMenu").style.display = "grid";
    document.getElementById("MainBoxBottomMenu").style.display = "grid";

try{
    for(let i = 1; i<=  57; i++){
      let compartment = "compartment#"+i;
      if(i!== 1 && 
        i!== 2 &&
        i!== 3 &&
        i!== 4 &&
        i!== 5 &&
        i!== 6 &&
        i!== 27 &&
        i!== 28 &&
        i!== 29 &&
        i!== 30 &&
        i!== 39 &&
        i!== 40 &&
        i!== 41 &&
        i!== 42 &&
        i!== 43 &&
        i!== 44 &&
        i!== 53 &&
        i!== 54 &&
        i!== 55 &&
        i!== 56 &&
        i!== 57  )
      document.getElementById(compartment).style.background = "green";
    }

    for(let i = 1; i<=  57; i++){
      let compartment = "compartment_"+i;
      if(i!== 1 && 
        i!== 2 &&
        i!== 3 &&
        i!== 4 &&
        i!== 5 &&
        i!== 6 &&
        i!== 27 &&
        i!== 28 &&
        i!== 29 &&
        i!== 30 &&
        i!== 39 &&
        i!== 40 &&
        i!== 41 &&
        i!== 42 &&
        i!== 43 &&
        i!== 44 &&
        i!== 53 &&
        i!== 54 &&
        i!== 55 &&
        i!== 56 &&
        i!== 57  )
      document.getElementById(compartment).style.background = "green";
    }
  }
  catch(err){console.log("FALTA MUDAR AQUI, NO COMPONENTDIDMOUNT()")}
    
    this.updateState()
    
  } 

  render() {
    
    return (
      <>   


    
        <div id="MainBoxTopMenu" className="parent grid-parent_boxes_top">

          
          <button className="button_boxes_light" style={{backgroundColor: this.state.caut_color}}>CAUT</button>


{/* inicio popup */}

<div className="child">

  <button class="button_boxes_light" onClick={this.open_menu}>SMOKE  <br/> MENU <i class="fa fa-arrow-right"></i></button>
  <div class="menubtn">
  <nav class="navmenu" >
    <h4 style={{color: "black"}}>Select the compartment(s) with smoke</h4>
    
     <ul class="thumbs-list">
      
     <li onClick={e => this.handleChange_new ("compartment#1")} >1</li>
     <li onClick={e => this.handleChange_new ("compartment#2")} >2</li>
     <li onClick={e => this.handleChange_new ("compartment#3")} >3</li>   
     <li onClick={e => this.handleChange_new ("compartment#4")} >4</li>
     <li onClick={e => this.handleChange_new ("compartment#5")} >5</li>
     <li onClick={e => this.handleChange_new ("compartment#6")} >6</li>
     <li onClick={e => this.handleChange_new ("compartment#7")} >7</li>
     <li onClick={e => this.handleChange_new ("compartment#8")} >8</li>
     <li onClick={e => this.handleChange_new ("compartment#9")} >9</li>
     <li onClick={e => this.handleChange_new ("compartment#10")} >10</li>
     <li onClick={e => this.handleChange_new ("compartment#11")} >11</li>
     <li onClick={e => this.handleChange_new ("compartment#12")} >12</li>
     <li onClick={e => this.handleChange_new ("compartment#13")} >13</li>
     <li onClick={e => this.handleChange_new ("compartment#14")} >14</li>
     <li onClick={e => this.handleChange_new ("compartment#15")} >15</li>
     <li onClick={e => this.handleChange_new ("compartment#16")} >16</li>
     <li onClick={e => this.handleChange_new ("compartment#17")} >17</li>
     <li onClick={e => this.handleChange_new ("compartment#18")} >18</li>
     <li onClick={e => this.handleChange_new ("compartment#19")} >19</li>
     <li onClick={e => this.handleChange_new ("compartment#20")} >20</li>
     <li onClick={e => this.handleChange_new ("compartment#21")} >21</li>
     <li onClick={e => this.handleChange_new ("compartment#22")} >22</li>
     <li onClick={e => this.handleChange_new ("compartment#23")} >23</li>   
     <li onClick={e => this.handleChange_new ("compartment#24")} >24</li>
     <li onClick={e => this.handleChange_new ("compartment#25")} >25</li>
     <li onClick={e => this.handleChange_new ("compartment#26")} >26</li>
     <li onClick={e => this.handleChange_new ("compartment#27")} >27</li>
     <li onClick={e => this.handleChange_new ("compartment#28")} >28</li>
     <li onClick={e => this.handleChange_new ("compartment#29")} >29</li>
     <li onClick={e => this.handleChange_new ("compartment#30")} >30</li>
     <li onClick={e => this.handleChange_new ("compartment#31")} >31</li>
     <li onClick={e => this.handleChange_new ("compartment#32")} >32</li>
     <li onClick={e => this.handleChange_new ("compartment#33")} >33</li>   
     <li onClick={e => this.handleChange_new ("compartment#34")} >34</li>
     <li onClick={e => this.handleChange_new ("compartment#35")} >35</li>
     <li onClick={e => this.handleChange_new ("compartment#36")} >36</li>
     <li onClick={e => this.handleChange_new ("compartment#37")} >37</li>
     <li onClick={e => this.handleChange_new ("compartment#38")} >38</li>
     <li onClick={e => this.handleChange_new ("compartment#39")} >39</li>
     <li onClick={e => this.handleChange_new ("compartment#40")} >40</li>
     <li onClick={e => this.handleChange_new ("compartment#41")} >41</li>
     <li onClick={e => this.handleChange_new ("compartment#42")} >42</li>
     <li onClick={e => this.handleChange_new ("compartment#43")} >43</li>   
     <li onClick={e => this.handleChange_new ("compartment#44")} >44</li>
     <li onClick={e => this.handleChange_new ("compartment#45")} >45</li>
     <li onClick={e => this.handleChange_new ("compartment#46")} >46</li>
     <li onClick={e => this.handleChange_new ("compartment#47")} >47</li>
     <li onClick={e => this.handleChange_new ("compartment#48")} >48</li>
     <li onClick={e => this.handleChange_new ("compartment#49")} >49</li>
     <li onClick={e => this.handleChange_new ("compartment#50")} >50</li>
     <li onClick={e => this.handleChange_new ("compartment#51")} >51</li>
     <li onClick={e => this.handleChange_new ("compartment#52")} >52</li>
     <li onClick={e => this.handleChange_new ("compartment#53")} >53</li>   
     <li onClick={e => this.handleChange_new ("compartment#54")} >54</li>
     <li onClick={e => this.handleChange_new ("compartment#55")} >55</li>
     <li onClick={e => this.handleChange_new ("compartment#56")} >56</li>
     <li onClick={e => this.handleChange_new ("compartment#57")} >57</li>

  
     



     
    </ul>
  </nav>
  </div>

</div>

{/* fim popup */}

          



        
        <h1 style={{textAlign: "center"}}>OVERHEAD BINS</h1>

<h1> </h1>
          <button className="button_boxes_light">INFO </button>
        </div>

        <div id="MainBox" className="parent grid-parent">
          <div className="child">



            <div className="plane" onClick={this.activateBins} >
              <div className="cockpit"></div>

              <ol class="cabin fuselage">
                <li class="row row--1">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#1" />
                      <label_first_aid id = "compartment_1"for="compartment1_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#2" />
                      <label_first_aid id = "compartment_2"for="compartment2_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--2">
                  <ol class="seats" type="A">
                  <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#3" />
                      <label_first_aid id = "compartment_3"for="compartment3_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#4" />
                      <label_first_aid id = "compartment_4"for="compartment4_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--3">
                  <ol class="seats" type="A">
                  <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#5" />
                      <label_first_aid id = "compartment_5"for="compartment5_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#6" />
                      <label_first_aid id = "compartment_6"for="compartment6_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--4">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#7"  onChange={(e) => this.handleChange("compartment#7")} />
                      <label id="compartment_7" for="compartment7_">7</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#8"  onChange={(e) => this.handleChange("compartment#8")} />
                      <label id="compartment_8" for="compartment8_">8</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--5">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#9"  onChange={(e) => this.handleChange("compartment#9")} />
                      <label id="compartment_9" for="compartment9_">9</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#10"  onChange={(e) => this.handleChange("compartment#10")} />
                      <label id="compartment_10" for="compartment10_">10</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--6">
                  <ol class="seats" type="A">
                  <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#11"  onChange={(e) => this.handleChange("compartment#11")} />
                      <label id="compartment_11" for="compartment11_">11</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#12"  onChange={(e) => this.handleChange("compartment#12")} />
                      <label id="compartment_12" for="compartment12_">12</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--7">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#13"  onChange={(e) => this.handleChange("compartment#13")} />
                      <label id="compartment_13" for="compartment13_">13</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#14"  onChange={(e) => this.handleChange("compartment#14")} />
                      <label id="compartment_14" for="compartment14_">14</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--8">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#15"  onChange={(e) => this.handleChange("compartment#15")} />
                      <label id="compartment_15" for="compartment15_">15</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#16"  onChange={(e) => this.handleChange("compartment#16")} />
                      <label id="compartment_16" for="compartment16_">16</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--9">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#17"  onChange={(e) => this.handleChange("compartment#17")} />
                      <label id="compartment_17" for="compartment17_">17</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#18"  onChange={(e) => this.handleChange("compartment#18")} />
                      <label id="compartment_18" for="compartment18_">18</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--10">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#19" onChange={(e) => this.handleChange("compartment#19")} />
                      <label id="compartment_19" for="compartment19_">19</label>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#20"  onChange={(e) => this.handleChange("compartment#20")} />
                      <label id="compartment_20" for="compartment20_">20</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--11">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#21" onChange={(e) => this.handleChange("compartment#21")} />
                    <label id="compartment_21" for="compartment21_">21</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#22" onChange={(e) => this.handleChange("compartment#22")} />
                    <label id="compartment_22" for="compartment22_">22</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--12">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#23" onChange={(e) => this.handleChange("compartment#23")} />
                    <label id="compartment_23" for="compartment23_">23</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#24" onChange={(e) => this.handleChange("compartment#24")} />
                    <label id="compartment_24" for="compartment24_">24</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--13">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#25" onChange={(e) => this.handleChange("compartment#25")} />
                    <label id="compartment_25" for="compartment25_">25</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#26" onChange={(e) => this.handleChange("compartment#26")} />
                    <label id="compartment_26" for="compartment26_">26</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--14">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#27" />
                      <label_first_aid id = "compartment_27"for="compartment27_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#28" />
                      <label_first_aid id = "compartment_28"for="compartment28_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--15">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#29" />
                      <label_first_aid id = "compartment_29"for="compartment29_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#30" />
                      <label_first_aid id = "compartment_30"for="compartment30_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--16">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#31" onChange={(e) => this.handleChange("compartment#31")} />
                    <label id="compartment_31" for="compartment31_">31</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#32" onChange={(e) => this.handleChange("compartment#32")} />
                    <label id="compartment_32" for="compartment32_">32</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--17">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#33" onChange={(e) => this.handleChange("compartment#33")} />
                    <label id="compartment_33" for="compartment33_">33</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#34" onChange={(e) => this.handleChange("compartment#34")} />
                    <label id="compartment_34" for="compartment34_">34</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--18">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#35" onChange={(e) => this.handleChange("compartment#35")} />
                    <label id="compartment_35" for="compartment35_">35</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#36" onChange={(e) => this.handleChange("compartment#36")} />
                    <label id="compartment_36" for="compartment36_">36</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--19">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#37" onChange={(e) => this.handleChange("compartment#37")} />
                    <label id="compartment_37" for="compartment37_">37</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#38" onChange={(e) => this.handleChange("compartment#38")} />
                    <label id="compartment_38" for="compartment38_">38</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--20">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#39" />
                      <label_first_aid id = "compartment_39"for="compartment39_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#40" />
                      <label_first_aid id = "compartment_40"for="compartment40_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--21">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#41" />
                      <label_first_aid id = "compartment_41"for="compartment41_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#42" />
                      <label_first_aid id = "compartment_42"for="compartment42_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--22">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#43" />
                      <label_first_aid id = "compartment_43"for="compartment43_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#44" />
                      <label_first_aid id = "compartment_44"for="compartment44_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--23">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#45" onChange={(e) => this.handleChange("compartment#45")} />
                    <label id="compartment_45" for="compartment45_">45</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#46" onChange={(e) => this.handleChange("compartment#46")} />
                    <label id="compartment_46" for="compartment46_">46</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--24">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#47" onChange={(e) => this.handleChange("compartment#47")} />
                    <label id="compartment_47" for="compartment47_">47</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#48" onChange={(e) => this.handleChange("compartment#48")} />
                    <label id="compartment_48" for="compartment48_">48</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--25">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#49" onChange={(e) => this.handleChange("compartment#49")} />
                    <label id="compartment_49" for="compartment49_">49</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#50" onChange={(e) => this.handleChange("compartment#50")} />
                    <label id="compartment_50" for="compartment50_">50</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--26">
                  <ol class="seats" type="A">
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#51" onChange={(e) => this.handleChange("compartment#51")} />
                    <label id="compartment_51" for="compartment51_">51</label>
                    </li>
                    <li class="seat">
                    <input type="checkbox" value="checkbox" disabled id="compartment#52" onChange={(e) => this.handleChange("compartment#52")} />
                    <label id="compartment_52" for="compartment52_">52</label>
                    </li>
                  </ol>
                </li>
                <li class="row row--22">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#53" />
                      <label_first_aid id = "compartment_53"for="compartment53_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#54" />
                      <label_first_aid id = "compartment_54"for="compartment54_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--22">
                  <ol class="seats" type="A">
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#55" />
                      <label_first_aid id = "compartment_55"for="compartment55_">+ </label_first_aid>
                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#56" />
                      <label_first_aid id = "compartment_56"for="compartment56_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                <li class="row row--22">
                  <ol class="seats" type="A">
                    <li class="seat">

                    </li>
                    <li class="seat">
                      <input type="checkbox" value="checkbox" disabled id="compartment#57" />
                      <label_first_aid id = "compartment_57"for="compartment57_">+ </label_first_aid>
                    </li>
                  </ol>
                </li>
                
              </ol>
              <div className="cockpit_inv"></div>

              {/*
 <div className="exit exit--back fuselage">
 </div>
  */}
            </div>
          </div>
        </div>


        <div class="row">
  <div class="column">
  <div className="boxes_lock_unlock_all_container">ALL BINS
              <button className="boxes_lock_unlock_all_bottom" onClick={this.lockUnlockAll}>{this.state.lock_state}</button>
            </div>
  </div>
  <div class="column">
  <div className="boxes_smoke_alert_container" style={{display:this.state.smoke_reset}}>
              <button className="boxes_smoke_alert_red_square"></button>
              <button className="boxes_smoke_alert_smoke_detected" style={{color: "white"}}>SMOKE DETECTED</button>
              <button className="boxes_smoke_alert_smoke_reset" onClick={this.smokeReset}>SMOKE RESET</button>
            </div>
  </div>
</div> 


   
          
          
        <div id="MainBoxBottomMenu" className="parent grid-parent_boxes_bottom">
          <button className="button_boxes_light">Screen</button>

          {/* 
          <div class="f1">
            <div className="parent grid-parent_boxes_internal"><IconContext.Provider value={{ color: "rgb(58,60,62)", size: "3em" }}>
                <button className="button_boxes_dark"><FaCaretLeft /></button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_light" onClick={this.lockUnlockAll}>{this.state.lock_state}</button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_dark" style={{fontSize:"1em"}}>x</button>
                <button className="button_boxes_dark">
                  <FaCaretRight />
                </button>
              </IconContext.Provider>
            </div>
          </div>
          

          <button
            className="button_boxes_light"
            style={{backgroundColor: "rgb(11,217,14)"}}>Cabin Status</button>
            */}
        </div>


        

      </>
    );
  }
}

export default App;